.react-calendar__navigation__label, .react-calendar__month-view__weekdays__weekday{
	font-weight: bold;
	font-size: 15px;
}

.react-calendar__navigation{
	margin-bottom: 0 !important;
}

.react-calendar__month-view__weekdays{
	background-color: #EFEAEA;
}

.react-calendar__month-view__weekdays__weekday{
	padding: 1em !important;
}
.react-calendar__tile{
	padding: 1.3em 0em !important;
	font-size: 14px;
	border-radius: 4px;
}

.react-calendar{
	width: 100% !important;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus, .react-calendar__tile--active{
	background-color: #0A9297 !important;
}

.react-calendar__navigation__arrow{
	font-size: 15px !important;
}