#nav-icon2{
	float: left;
	width: 30px;
    height: 50px;
    position: relative;
    margin: 50px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
	/* z-index: 1; */
	margin: 0 0 0 10px;
}

#nav-icon2 span {
    display: block;
    position: absolute;
    height: 4px;
    width: 50%;
    background: white;
    opacity: 1;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); 
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}
#nav-icon2 span:nth-child(odd) {
    left: 0px;
    border-radius: 9px 0 0 9px;
}
#nav-icon2 span:nth-child(even) {
    left: 50%;
    border-radius: 0 9px 9px 0;
}
#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
    top: 10px;
}
#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
    top: 22px;
}
#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
    top: 34px;
}
#nav-icon2.open span:nth-child(1) {
    left: 3px;
    top: 18px;
}
#nav-icon2.open span:nth-child(1), #nav-icon2.open span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}
#nav-icon2.open span:nth-child(2) {
    left: calc(50% - 3px);
    top: 18px;
}
#nav-icon2.open span:nth-child(2), #nav-icon2.open span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
#nav-icon2.open span:nth-child(3) {
    left: -50%;
    opacity: 0;
}
#nav-icon2.open span:nth-child(4) {
    left: 100%;
    opacity: 0;
}
#nav-icon2.open span:nth-child(5) {
    left: 3px;
    top: 26px;
}
#nav-icon2.open span:nth-child(6) {
    left: calc(50% - 3px);
    top: 26px;
}